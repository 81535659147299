import React from 'react';
import styles from './EditProfile.module.css';
import FilePicker from '../../../lib/components/FilePicker/FilePicker';
import Navbar from '../../../lib/components/Navbar/Navbar';
import CenterContainer from '../../../lib/components/CenterContainer/CenterContainer';
import Button from '../../../lib/components/Button/Button';
import DashboardWrapper from '../../DashboardWrapper';
import { Helmet } from 'react-helmet';

const EditProfile = ({
  userGlobalState,
  onChangeField = () => {},
  onSubmit = () => {},
  value = {}
}) => {

  return (
    <>
      <Helmet>
        <title>Edit Profile</title>
      </Helmet>
      <Navbar />
      <CenterContainer>
        <DashboardWrapper>
          {userGlobalState && userGlobalState.artist ? (
            <form onSubmit={onSubmit} className={styles.form__container}>
              <div className={styles.input_section}>
                <p>Username</p>
                <input
                  value={value.name || userGlobalState.artist.name}
                  onChange={onChangeField.bind(null, { fieldName: 'name' })}
                  type="text"
                  placeholder="Username"
                />
              </div>

              <div className={styles.input_section}>
                <p>Bio</p>
                <p style={{ fontSize: '13px', color: '#c4c4c4' }}>
                  Max characters: 205
                </p>
                <textarea
                  maxlength="205"
                  rows="7"
                  cols="40"
                  value={value.bio}
                  onChange={onChangeField.bind(null, { fieldName: 'bio' })}
                  placeholder="Insert your Bio"
                />
              </div>

              <div className={styles.input_section}>
                <p>Profile Picture</p>
                <FilePicker
                  value={value.avatarImgUrl}
                  onChange={onChangeField.bind(null, {
                    fieldName: 'avatarImgUrl'
                  })}
                />
              </div>

              <Button type="submit">Save</Button>
            </form>
          ) : (
            'Loading...'
          )}
        </DashboardWrapper>
      </CenterContainer>
      <br />
      <br />
      <br />
    </>
  );
};

export default EditProfile;
