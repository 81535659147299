
import React from "react";
import EditProfileContainer from '../../../Dashboard/profile/containers/EditProfileContainer';


const EditProfilePage = ({ location }) => {

  return (
    <EditProfileContainer location={location}/>
  )
}

export default EditProfilePage;
