import React, { useContext } from 'react';
import EditProfile from '../views/EditProfile';
import { UserContext } from '../../../Context/UserContext/UserContext';
import { FeedbackContext } from '../../../Context/FeedbackContext/FeedbackContext';
import useSetState from '../../../utils/useSetState';
import updateArtist from './utils/updateArtist';
import { cloneDeep } from 'lodash';

const EditProfileContainer = ({ location }) => {
  const { userGlobalState, setUserGlobalState } = useContext(UserContext);
  const { setFeedbackState } = useContext(FeedbackContext);

  const [state, setState] = useSetState(
    userGlobalState && userGlobalState.artist ? userGlobalState.artist : {}
  );

  const onSubmit = (e) => {
    e.preventDefault();
    updateArtist({
      state,
      userGlobalState,
      setUserGlobalState,
      setFeedbackState
    });
  };

  const onChangeField = ({ fieldName }, e) => {
    const stateClone = cloneDeep(state);
    stateClone[fieldName] = e.target.value;
    setState(stateClone);
  };

  return (
    <EditProfile
      onChangeField={onChangeField}
      onSubmit={onSubmit}
      value={state}
      userGlobalState={userGlobalState}
    />
  );
};

export default EditProfileContainer;
