import { api_base_url } from '../../../../../global_data';
import axios from 'axios';
import { navigate } from 'gatsby';
import { cloneDeep } from 'lodash';

const updateArtist = async ({
  state,
  userGlobalState,
  setFeedbackState,
  setUserGlobalState
}) => {
  try {
    if (!state.name) {
      setFeedbackState({
        isActive: true,
        isError: true,
        isLoading: false,
        message: `The name can't be empty`
      });
    } else {
      const artistId = userGlobalState.artist._id;
      const userId = userGlobalState.user._id;

      setFeedbackState({
        isActive: true,
        isLoading: true
      });

      const newArtist = {
        ...state,
        userId
      };

      const formData = new FormData();

      formData.append('avatarImgUrl', state.avatarImgUrl);

      formData.append('data', JSON.stringify(newArtist));

      const res = await axios({
        url: `${api_base_url}/artists/${artistId}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      });

      const avatarImgUrl = res.data.avatarImgUrl;

      setUserGlobalState({ artist: { ...cloneDeep(newArtist), avatarImgUrl } });

      navigate('/dashboard/profile');

      setFeedbackState({
        isActive: true,
        isError: false,
        isLoading: false,
        message: 'Profile Updated'
      });
    }
  } catch (err) {
    console.log('err', err);
    setFeedbackState({
      isActive: true,
      isError: true,
      isLoading: false,
      message: 'Error'
    });
  }
};

export default updateArtist;
